import React from 'react';
import Carousel from 'react-multi-carousel';
import { useStaticQuery, graphql } from 'gatsby';

import 'react-multi-carousel/lib/styles.css';
import { Col, Row } from 'react-bootstrap';

const responsive = {
  ultrawide: {
    breakpoint: { max: 5000, min: 2560 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 2560, min: 1600 },
    items: 4,
  },
  desktopsm: {
    breakpoint: { max: 1600, min: 1200 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1200, min: 576 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 1,
  },
};

const Footer = () => {
  const clients = useStaticQuery(
    graphql`
      query {
        allClientsJson {
          edges {
            node {
              clients {
                name
                img
              }
            }
          }
        }
      }
    `
  );

  return (
    <footer className="footer">
      <Row>
        <Col sm={12} md={3} className="footer__title">
          <h2>
            Our happy <strong>clients</strong>
          </h2>
        </Col>
        <Col sm={12} md={9}>
          <Carousel
            className="footer__clients"
            responsive={responsive}
            autoPlay
            infinite
            arrows={false}
          >
            {clients.allClientsJson.edges[0].node.clients.map(client => (
              <div className="footer__item" key={client.name}>
                {client.img !== '' ? (
                  <img
                    src={`/images/clients/${client.img}`}
                    alt={client.name}
                    draggable="false"
                  />
                ) : (
                  <span>{client.name}</span>
                )}
              </div>
            ))}
          </Carousel>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
