import { Link } from 'gatsby';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import NGLogo from '../../assets/images/omega-ng.svg';

const Header = () => (
  <Row className="header">
    <Col>
      <Link to="/" className="header__logo">
        <img src={NGLogo} alt="Net Group logo" />
      </Link>
    </Col>
  </Row>
);

export default Header;
