/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import { Container } from 'react-bootstrap';

import Header from './Project/Header';
import '../assets/scss/main.scss';
import Footer from './Footer';

const Layout = ({ children, borderless = false }) => (
  <>
    {borderless ? (
      <>
        <main>
          <Header />
          <Container fluid>{children}</Container>
        </main>
        <Footer />
      </>
    ) : (
      <>
        <div className="window-line window-line--top"></div>
        <main>
          <Container fluid className="portfolio-wrap">
            {children}
          </Container>
        </main>
        <Footer />
        <div className="window-line window-line--bottom"></div>
      </>
    )}
  </>
);

export default Layout;
